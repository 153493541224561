.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px 0;
  color: white;
}

.App-content {
  display: flex;
  justify-content: center;
  background-color: yellow;
}

.calendly-embed-container {
  /* max-width: 500px; */
  flex: 1 0 0;
  width: 100%;
  margin: 0 auto;
  background-color: blue;
}
.calendly-badge-widget {
  right: auto !important;
  left: 20px !important;
}

.calendly-inline-widget{
 height: 500px;
}